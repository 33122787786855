import apiClient from "@/utils/apiClient";
import swr from "swr";
import qs from "qs";

export const useSearchEntries = (query) => {
  const filter = qs.stringify({
    populate: "*",
    filters: {
      $and: [
        {
          titulo: {
            $containsi: query,
          },
        },
        // {
        //   ediciones_giras: {
        //     name: {
        //       $eq: "2023"
        //     }
        //   }
        // }
      ],
    },
  });
  const { data, error } = swr(`/api/entradas?${filter}`, apiClient.get);
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useSearchFilms = (query) => {
  const fetcher = (...args) => fetch(...args).then(res => res.json());

  const { data, error } = swr(`${process.env.NEXT_PUBLIC_STRAPI_URL}/api/component-search-films?search=${query}`, fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useCategories = () => {
  const q = qs.stringify({
    populate: "*",
  });
  const { data, error } = swr(`/api/categories?${q}`, apiClient.get);

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useSearchVenues = (query) => {
  const filter = qs.stringify({
    populate: "*",
    filters: {
      $and: [
        {
          name: {
            $containsi: query,
          },
        },
      ],
    },
  });
  const { data, error } = swr(`/api/venues?${filter}`, apiClient.get);
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};
